import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import { serializeNode } from '../utils/wysiwyg';
import fetchJsonp from 'fetch-jsonp';

import SmartFeedFiltersComponent from '../components/SmartFeedWithFilters';

import renderHTML from 'react-render-html';

require('es6-promise').polyfill();

export default function HomepagePagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [vacancyLoading, setVacancyLoading] = useState(true);

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.title}</title>
        <meta
          name="description"
          content="Find your new career at Progress Housing Group  today."
        />
      </Helmet>

      <div className="home">
        <section className="app-content">
          {contentDataData && contentDataData.length > 0 && (
            <div>
              <div className="app-hero-wrapper app-hero-home app-hero-video">
                <div className="app-hero-video-wrap">
                  <div className="video_item">
                    <div className="video_position">
                      <video
                        preload="true"
                        muted
                        autoPlay={true}
                        loop
                        playsInline
                      >
                        {/* <source
                          src="https://cdn1.accropress.com/ProgressGroupHousingCareers/Untitled+(1).mp4"
                          type="video/mp4"
                        /> */}

                        <source
                          src="https://new-smart-feed.vacancy-filler.co.uk/Career_Page_Files/progress_group_housing/Video/Main.mp4"
                          type="video/mp4"
                        />

                        <img
                          src={contentDataData[0].children[0].value}
                          alt="Your browser does not support the <video> tag"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="app-hero-background-image"
                  style={{
                    backgroundImage: `url(${contentDataData[0].children[0].value})`,
                  }}
                ></div>
                <div className="app-hero-background-overlay"></div>
                <div className="app-hero-content-center"></div>
                <div className="app-hero-content-bottom"></div> */}
              </div>

              <div className="app-content-content">
                <div className="content-block-intro">
                  <div className="container">
                    <div className="homepage-opener-section row">
                      <div className="col-xs-12 homepage-opener-section-center">
                        <h1>{contentMetaData.title}</h1>
                        {/* <div className="page-content-wysiwyg">
                          {JSON.parse(contentDataData[0].children[1].value) &&
                            JSON.parse(contentDataData[0].children[1].value)
                              .length > 0 &&
                            JSON.parse(
                              contentDataData[0].children[1].value
                            ).map((item, i) => (
                              <div key={i}>
                                {renderHTML(serializeNode(item))}
                              </div>
                            ))}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content-block-light">
                  <div className="content-block-vacancies">
                    <div className="container">
                      <SmartFeedFiltersComponent
                        acancyLoading={vacancyLoading}
                        setVacancyLoading={setVacancyLoading}
                        apiKey={'g6SDL6CeOWEOBExxiGnCQCpts407QWdgdmR5BqykIAo'}
                        group={false}
                        gdprKey=""
                        CompanyGroupName="Progress Housing Group "
                        VacancyPageBaseURL="https://progress-group-housing.pages.dev/vacancies/vacancy/"
                        ReturnToSearchURL="https://progress-group-housing.pages.dev/vacancies/"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </Layout>
  );
}
